:root {
  --main-color: #0099ff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
}

textarea:focus, input:focus{
  outline: none;
}

.auth-form {
  width: 280px;
  display: grid;
  grid-template-columns: 280px;
  justify-items: center;
}

.auth-header {
  width: 250px;
  color: var(--main-color);
  text-align: center;
  align-self: center;
}

.auth-main {
  margin: 64px auto 0 auto;
  padding: 64px 16px;
  border-radius: 16px;
  max-width: 500px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-items: center;
  background-color: white;
  -webkit-box-shadow: 10px 10px 32px -24px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 32px -24px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 32px -24px rgba(0,0,0,0.75);
}

.auth-button {
  border-radius: 16px;
  border: none;
  height: 64px;
  padding: 16px;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  background-color: var(--main-color);
  color: white;
  cursor: pointer;
}

#password-reset-textfield {
  height: 48px;
  border: none;
  border-radius: 8px 0 0 8px;
  padding: 8px 4px 0 8px;
  background-color: #eee;
  width: 148px;
  display: block;
  justify-items: center;
}

.auth-issue-text {
  background-color: light;
  text-align: center;
}

.password-reset-text-container {
  display: grid;
  grid-template-columns: 160px 40px;
}

.password-toggle-text-form {
  background-color: #eee;
  border-radius: 0px 8px 8px 0px;
  height: 24px;
  margin: 0;
  padding: 16px 8px;
  align-self: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
